<template>
  <base-section id="wbs">
    <base-section-heading
      color="grey lighten-2"
      title="Whistleblowing"
    />

      <template>
        <div class="mx-2 text-center">Laporan anda akan bermakna untuk Takaful Umum.<br>Jika anda mengetahui atau mendengar <strong>pelanggaran/kecurangan</strong> di lingkungan Takaful Umum.<br><strong>Laporkan segera melalui pengisian formulir di bawah ini</strong></div>
        <v-container>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
        <v-row>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-select
          :items="items"
          label="Pelaporan Pelanggaran *"
          required="required"
          outlined
          v-model ="reported"
          :rules="reportedRules"
          ></v-select>
          </v-col>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-text-field
              label="Nama Pelapor"
              required="required"
              placeholder="Nama Anda (opsional)"
              outlined
              v-model ="name"
              :rules  ="nameRules"
            />
          </v-col>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-text-field
              label="No. Telp/HP *"
              required="required"
              placeholder="Isi Nomor Telp/HP Anda"
              outlined
              v-model ="phone"
              :rules  ="phoneRules"
            />
          </v-col>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-text-field
              label="Email *"
              required="required"
              placeholder="Isi Email Anda"
              outlined
              v-model ="email"
              :rules  ="emailRules"
            />
          </v-col>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-text-field
              label="Nama Terlapor *"
              required="required"
              placeholder="Nama Terlapor"
              outlined
              v-model ="namereport"
              :rules  ="namereportRules"
            />
          </v-col>
          <v-col
            md ="6"
            sm ="12"
            cols="12"
          >
          <v-dialog
              ref="reportdate"
              v-model="reportdate_menu"
              :return-value.sync="reportdate"
              persistent
              width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              slot="activator"
              :value="reportdate_value"
              label="Tanggal Kejadian"
              prepend-inner-icon="mdi-calendar"
              :rules="reportdate_rules"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              ></v-text-field>
            </template>
              <v-date-picker v-model="reportdate" scrollable id="reportdate">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="reportdate_menu = false">Cancel</v-btn>
              <v-btn color="primary" @click="SetDate(reportdate)">OK</v-btn>
              </v-date-picker>
          </v-dialog>
          </v-col>
          <v-col
            md ="12"
            sm ="12"
            cols="12"
          >
          <v-textarea
              label="Informasi Pelanggaran *"
              required="required"
              placeholder="Isi Informasi Pelanggaran"
              hint="(Memuat Nama & Jabatan Terlapor, Kronologis, Waktu dan Lokasi)"
              persistent-hint
              outlined
              v-model ="notes"
              :rules  ="notesRules"
            />
          </v-col>
          <v-col
            md ="12"
            sm ="12"
            cols="12"
          >
          <input type="file" ref="attachment" @change="UploadAttachment" style="display:none">
           <v-text-field
           :value="uploads.title"
            prepend-icon="mdi-paperclip"
            :readonly="true"
            label="Lampiran Bukti"
            hint="(ZIP, RAR, DOC, XLS, PDF, JPG, MP4, dan MP3)"
            persistent-hint
            @click="GetAttachment"
           ></v-text-field>
          </v-col>
           <v-col
            md ="12"
            sm ="12"
            cols="12"
          >
            <v-checkbox label="DATA DAN INFORMASI YANG SAYA BERIKAN BENAR SERTA DAPAT DIPERTANGGUNGJAWABKAN" v-model="terms"></v-checkbox>
           </v-col>
          <v-col
            md ="12"
            sm ="12"
            cols="12"
          >
            <v-btn
            color="primary"
            tile="tile"
            class="font-weight-bold ml-2 mb-2"
            block
            @click="SaveConfirm"
            >
            Kirim Whistle Blowing
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
        <v-dialog
          v-model="dialog"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="body-1 grey lighten-2">
              Kirim?
            </v-card-title>

            <v-card-text class="body-2">
              
            </v-card-text>


            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="dialog = false"
              >
                Batal
              </v-btn>
              
              <v-btn
                color="primary"
                text
                :disabled="dialog_ok === 'Ok' ? false:true"
                @click ="SaveData"
              >
                {{dialog_ok}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
            :timeout="snackbar_timeout"
            v-model="snackbar"
          >
            {{ snackbar_text }}
          <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              Close
         </v-btn>
      </v-snackbar><v-dialog
            v-model="dialog_notification"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="body-1 grey lighten-2">
                Notifikasi
              </v-card-title>
              <v-card-text class="body-2">
                {{ dialog_text }}
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </template>
  </base-section>
</template>

<script>
  import imageCompression from 'browser-image-compression'
  export default {
    name: 'SectionComplain',
    data: () => ({
      valid: true,
      dialog: false,
      dialog_ok: 'Ok',
      dialog_notification: false,
      dialog_text: '',
      snackbar: false,
      snackbar_text: '',
      snackbar_timeout: 5000,
      items: ['Pelanggaran Pegawai', 'Pelanggaran Direksi', 'Pelanggaran Dewan Komisaris', 'Pelanggaran Lain-Lain'],
      reported: '',
      reportedRules: [
         v => !!v || 'Mohon pilih Pelanggaran Pelaporan'
      ],
      name: '',
      nameRules: [
         v => !!v || 'Mohon masukkan nama anda',
         v => (v && v.length > 1) || 'Nama tidak boleh 1 huruf',
      ],
      phone: '',
      phoneRules: [
         v => !!v || 'Mohon masukkan nomor handphone anda',
         v => Number.isInteger(Number(v)) || "Mohon isi dengan angka",
      ],
      email: '',
      emailRules: [
         v => !!v || 'Mohon masukkan email anda',
         v => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
      notes: '',
      notesRules: [
         v => !!v || 'Mohon masukkan catatan/pengaduan anda',
         v => (v && v.length > 10) || 'Catatan tidak boleh kurang dari 10 huruf',
      ],
      namereport: '',
      namereportRules: [
         v => !!v || 'Mohon masukkan nama terlapor',
      ],
      uploads_attachment: '',
      uploads: {
        src: '',
        title: '',
      },
      terms: false,
      reportdate: new Date().toISOString().substr(0, 10),
      reportdate_menu: false,
      reportdate_date: new Date().toISOString().substr(0, 10),
      reportdate_rules: [v => !!v || 'Mohon masukkan Tanggal Kejadian'],
    }),
    computed: {
      reportdate_value () {
          return this.reportdate ? this.$moment(this.reportdate).format('LL') : ''
      }
    },
    methods: {
      SaveConfirm () {
        if (this.terms === false) {
          this.snackbar = true
          this.snackbar_text = 'Mohon centang `DATA DAN INFORMASI YANG SAYA BERIKAN BENAR SERTA DAPAT DIPERTANGGUNGJAWABKAN`'
        } else {
          if (this.$refs.form.validate()) {
            this.dialog = true
          }
        }
      },
      SaveData () {
        this.dialog_ok = 'Loading...'
        var today =  this.$functions.TodayYYYYMMDD()
        let formData = new FormData()
        formData.append('url_type', 'add')
        formData.append('ticket_type', 'W01')
        formData.append('ticket_dept', '027')
        formData.append('ticket_source', 'WEBSITE')
        formData.append('ticket_name', this.name)
        formData.append('ticket_description', this.notes)
        formData.append('ticket_refname', this.reported)
        formData.append('ticket_category', 'LAINLAIN')
        formData.append('ticket_pic', 'system')
        formData.append('ticket_subject', 'Ada Whistle Blowing dari '+this.name)
        formData.append('ticket_priority', 'H')
        formData.append('ticket_refno', '-')
        formData.append('ticket_address', '-')
        formData.append('ticket_reportname', this.namereport)
        formData.append('ticket_reportdate', this.reportdate_date)
        formData.append('ticket_email', this.email)
        formData.append('ticket_mobile', this.phone)
        formData.append('ticket_date', today)
        formData.append('ticket_targetdate', today)
        formData.append('ticket_idno', '')
        formData.append('ticket_idtype', '')
        formData.append('ticket_wbs', 'Y')
        if (this.uploads_attachment !== '') {
            formData.append('file', this.uploads_attachment, this.uploads_attachment.name)
        }
        this.$axios.post(this.$functions.SafeURL('apiWriteTicket', ``), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
        this.dialog = false
        if (response.data[0].feedback === 'Y') {
          this.dialog_notification = true
          this.dialog_text = 'Laporan Anda dengan nomor registrasi '+response.data[0].ticket_id+' telah kami terima dan akan ditindaklanjuti oleh Tim Whistleblowing System. Terima kasih'
          this.dialog_ok ="Ok"
          this.nopol = ''
          this.name = ''
          this.phone = ''
          this.email = ''
          this.notes = ''
        } else {
          this.snackbar = true
          this.snackbar_text = response.data[0].feedback
          this.dialog_ok ="Ok"
        }
      })
      .catch(e => {
          this.dialog = false
          this.snackbar = true
          this.snackbar_text = e.response
          this.dialog_ok ="Ok"
      })
      },
      GetAttachment () {
        this.$refs.attachment.click()
      },
      UploadAttachment () {
        var input1 = event.target
        if (input1.files && input1.files[0]) {
            var filename1 = input1.files[0].name
            var extension1 = filename1.split('.').pop().toLowerCase()
            if (extension1 === 'jpeg' || extension1 === 'jpg' || extension1 === 'png') {
                var imageFile1 = input1.files[0]
                var options1 = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
                }
                let data1 = this
                imageCompression(imageFile1, options1)
                .then(function (compressedFile) {
                    data1.uploads.src = URL.createObjectURL(compressedFile)
                    data1.uploads_attachment = compressedFile
                    data1.uploads.title = ''
                })
                .catch(function (error) {
                    this.snackbar = true
                    this.snackbar_text = error.message
                })
            } else {
                var reader1 = new FileReader()
                reader1.onload = (e) => {
                    this.uploads.src = e.target.result
                    this.uploads.title = filename1
                }
                this.uploads_attachment = input1.files[0]
                reader1.readAsDataURL(input1.files[0])
            }
        }
      },
      SetDate (date) {
        this.$refs.reportdate.save(date)
        this.reportdate_date = this.$moment(date).format('YYYY-MM-DD')
      }
    },
  }
</script>
